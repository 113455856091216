import React from 'react'
import Helmet from 'react-helmet'
import '../../assets/scss/init.scss'

import { AppLayout } from '@findrobbrodie/find-rob-brodie-common'

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <AppLayout>
        <div className="layout">
          <Helmet defaultTitle="Blog by Rob Brodie" />
          {children}
        </div>
      </AppLayout>
    )
  }
}

export default Layout
